* {
  box-sizing: border-box;
  margin: 0;
}

h1 {
  font-size: 24px;
  font-weight: 500;
  color: #143859;
}

h2 {
  font-size: 18px;
  font-weight: 600;
  color: #143859;
  margin-bottom: 8px;
}

h3 {
  font-size: 18px;
  font-weight: 400;
  color: #143859;
  margin-bottom: 4px;
}

h4 {
  font-size: 16px;
  font-weight: 400;
  color: #143859;
}

h5 {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: #72889b;
  letter-spacing: 0.6px;
}

p {
  font-size: 14px;
  color: #72889b;
}

a {
  font-size: 14px;
  color: #66c3cc;
  font-weight: 600;
  letter-spacing: 0px;
}

/*** button styles ***/

button {
  padding: 16px 24px;
  background-color: #66c3cc;
  border: none;
  border-radius: 4px;
  outline: none;
  font-size: 18px;
  color: white;
  font-weight: 500;
  cursor: pointer;
}

i {
  margin-right: 8px;
  vertical-align: middle;
  /* height: 18px; */
}

input[type="text"] {
  font-size: 16px;
  padding: 17px 16px;
  border: solid 1px #dce1e6;
  border-radius: 4px;
  outline: none;
  color: #143859;
  width: 100%;
}

input[type="text"]::placeholder {
  color: #dce1e6;
}

hr {
  border-top: 0px solid #f1f3f5;
  margin-top: 16px;
}

/**** landing ****/
.landing-background {
  background: #143859;
  min-height: 100vh;
}

.landing-background button {
  font-size: 16px;
  padding: 13px 16px;
}

button.demo-button {
  color: #66c3cc;
  background-color: transparent;
  border: solid 1px #5b748b;
  margin-right: 8px;
}

/**** home  ****/
.home-wrapper {
  height: 100vh;
  width: 100vw;
}

/***** pipeline *****/

.pipeline {
  background-color: white;
}

.pipeline h4 {
  margin-bottom: 8px;
}

.tab-bar {
  display: flex;
}

.tab-bar button {
  margin-top: 12px;
  margin-right: 16px;
  padding: 8px 4px;
  background-color: transparent;
  font-size: 14px;
  letter-spacing: 1.5px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 0;
  border-bottom: solid 3px transparent;
  opacity: 0.6;
}

.tab-button.active {
  border-bottom: solid 3px #66c3cc;
  opacity: 1;
}

.tab-bar h5:first-child {
  margin-right: 24px;
}

.empty-state {
  text-align: center;
  height: calc(100vh - 172px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pipeline-list {
  height: calc(100vh - 172px);
}

/* .empty-state h4 {
  margin-bottom: 8px;
} */

.pipeline-footer button {
  margin-top: -28px;
  border-radius: 100px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/**** leads ****/
.lead-screen-1 button {
  width: 100%;
}

.lead-first-inputs {
  display: flex;
}

.first-input {
  margin-right: 16px;
}

/*** lo ***/
.recents {
  margin-top: 48px;
}

.loan-officer-row h4 {
  margin-bottom: 4px;
}

/**** lead review ****/

.lead-screen-3 .advance {
  width: 100%;
  margin-top: 40px;
}

.lead-screen-3 h5 {
  margin-bottom: 16px;
}

.lead-screen-3 h4 {
  margin-bottom: 8px;
}

.review-card {
  padding: 20px 16px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.review-card p {
  font-size: 12px;
}

.review-lender {
  margin-bottom: 16px;
}

.review-phone {
  margin-right: 8px;
}

.lil-dot {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #ced5dc;
  margin-right: 8px;
}

/***** buyer activity ****/

.buyer-activity {
  min-height: 100vh;
  background-color: #f1f3f5;
  /* text-align: center; */
}

.buyer-activity-wrapper {
  padding: 0 0 16px 0;
}

.buyer-activity h2 {
  margin-bottom: 16px;
}

.link-button {
  display: block;
  margin: 0 auto;
  margin-top: 16px;
  padding: 0;
  background-color: transparent;
  color: #66c3cc;

  font-size: 14px;
  font-weight: 600;
}

.icon-and-word {
  display: flex;
}

.connected-word {
  color: #39bf7c;
  font-weight: 500;
  line-height: 24px;
}

/***** status row *****/

.status-row h4 {
  margin-bottom: 6px;
}

.application-status {
  display: flex;
  margin: 16px 0;
}

.application-status h4 {
  margin-bottom: 4px;
}

.status-icon i {
  margin: 0;
}

/******/

.outstanding-fu {
  color: #db3751;
}

.PA-tip button {
  padding: 12px 16px;
  background-color: transparent;
  color: #66c3cc;
  font-size: 16px;
  font-weight: 500;
  border: solid 1px #72889b;
  flex-shrink: 0;
}

/**** PA screen ***/

.pa-screen {
  height: 100vh;
  width: 100vw;
  background-color: white;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 24px; /* Set a specific slider handle width */
  height: 24px; /* Slider handle height */
  background: #143859; /* Green background */
  border-radius: 50%;
}

.FF-visible {
  bottom: 16px;
}

.FF-invisible {
  visibility: hidden;
}

/***** media query for phone wrapper ****/
@media only screen and (min-width: 500px) {
  body {
    background-color: #f2f5f7;
    /* padding-top: 40px; */
  }

  .App {
    padding-top: 40px;
    width: 100vw;
    height: 100vh;
  }

  .landing-wrapper {
    margin-top: -40px;
    /* margin-bottom: 40px; */
  }

  .landing-background button {
    padding: 14px 24px;
  }

  button.demo-button {
    margin-right: 16px;
  }

  .home-wrapper {
    width: 100%;
    height: 100%;
    background-color: #143859;
  }

  .phone-wrapper {
    background-color: white;
    width: 375px;
    height: 667px;
    overflow: scroll;
    margin: 0 auto;
    margin-top: 40px;
    /* padding-top: 40px; */
    box-shadow: 0 12px 40px rgba(22, 23, 29, 0.15);
  }

  .empty-state,
  .pipeline-list {
    height: calc(667px - 172px);
    overflow: scroll;
  }

  .buyer-activity {
    min-height: 0;
    height: 667px;
    overflow: scroll;
  }

  .pa-screen {
    height: 100%;
    width: 100%;
    /* width: 100vw; */
    background-color: white;
  }

  .home-wrapper {
    height: 100%;
    width: 100%;
  }

  /* transitions */
  button.advance {
    transition: background-color 200ms ease-out;
    cursor: pointer;
  }
  button.advance:hover {
    background-color: #2fb9c6;
  }

  .FF-visible {
    bottom: 40px;
    transition: all 170ms ease-out;
  }
  .FF-visible:hover {
    background-color: black;
  }

  .loan-officer-row {
    cursor: pointer;
    transition: background-color 200ms ease-out;
  }
  .loan-officer-row:hover {
    background-color: #f1f3f5;
  }
}
